<template>
<div>
  <div class="mw-100" style="width: 26.25rem; margin:0 auto;">
    <div class="card text-center">
      <div class="card-body">
        <h1 class="card-title mb-5">Sign In</h1>

        <form id="signInForm" autocomplete="off" @submit.prevent="signIn">
          <!-- Email -->
          <p>
            <input 
              type="email" 
              id="email" 
              v-model="$v.form.email.$model" 
              maxlength="64"
              placeholder="Email" 
              autocomplete="off" 
              class="form-control"
              :class="{
                'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                'is-invalid': $v.form.email.$error
              }"
            />
          </p>
          <!-- Password -->
          <p>
            <input 
              type="password" 
              id="password" 
              v-model="$v.form.password.$model" 
              maxlength="64"
              placeholder="Password" 
              autocomplete="off" 
              class="form-control">
          </p>
          <!-- Sign In Button -->
          <p align="center">
            <button type="submit" :disabled="$v.form.$invalid" class="btn btn-primary">
              Sign In
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>

  <div class="text-gray text-center small my-2">
    <router-link :to="{ name: 'RequestPasswordResetRoute' }">Forgot Password?</router-link>
  </div>

</div>
</template>

<script>
  /* eslint-disable no-console */
  /* eslint-disable no-debugger */

  import { pcMixin } from '@/mixins/pcMixin.js';
  import { apiMixin } from '@/mixins/apiMixin.js';

  import { email } from 'vuelidate/lib/validators';
  import { required } from 'vuelidate/lib/validators';

  import Vue from 'vue';
  import { VueReCaptcha } from 'vue-recaptcha-v3';
  Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

  export default {
    name: "SignInView",

    mixins: [ pcMixin, apiMixin ],

    data() {
      return {
        sem: this.$store.getters.sem,
        pc: this.$store.getters.pc,

        form: {
          email: null,
          password: null
        }
      }
    },

    validations: {
      form: {
        email: { email, required },
        password: {  minlength: 1, required }
      }
    },

    methods: {
      async signIn() {
        this.pcSpinnerShow();

        // Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "signin".
        const token = await this.$recaptcha('signin');
        this.apiLogin(this.form.email, this.form.password, token)
        .then((response) => {
          // Get the Authorization token returned from PCAPI for later use with that API.
          this.sem.authorization = response.headers.authorization.substring(6);

          // Get the Account data
          this.apiGetAccount()
          .then((response) => {
            this.parseAccountPc(response.data);
            
            // Update the account's last login timestamp.
            this.apiUpdateLastLogin().then(()=>{}).catch(()=>{});

            this.$router.push({ name: 'PersonalInformationRoute' })
          })
          .catch(() => {
            this.pcModal("Your session has expired. Please log back in.")
            .then(() => { this.$router.push({ name: 'SignInRoute' }) })
            .catch(() => {})
          })
        })
        .catch((response) => {
          if (response.response.status == 401) {
            this.pcModal("Invalid email/password combination").catch(() => {});
          } else if (response.response.status == 403) {
            this.pcModal("You have been temporarily locked out due to too many failed login attempts. Please try again later.").catch(() => {});
          } else {
            this.pcModal("Internal error").catch(() => {});
          } 
        })
        .finally(() => { this.pcSpinnerHide(); })
      },

      parseAccountPc(data) {
        for (let key of Object.keys(data)) {
          if (key === "pAllowSale") {
            this.pc.doNotSell = !data[key]
          } else {
            this.pc[key] = data[key];
          }
        }
      }

    }
  }
</script>
